<template>
    <div class="grid p-fluid">
      <!-- {{ clientData }} -->
      <div class="col-12 md:col-12"><Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" /></div>
      <div class="col-12 md:col-4">
        <div class="card card-w-title" style="height: 800px">
          <div style="col-12 md;text-align:center">
            <img v-if="clientData.profile_image"
              :src="clientData.profile_image"
              style="width: 200px;height:200px; border-radius: 20%"
            />
            <img v-else
              src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
              style="width: 200px; border-radius: 20%"
            />
            <hr />
          </div>
          <div style="">
            <div class="grid">
              
              <div class="col-4 md:col-4"><b>Name</b> </div>
              <div class="col-8 md:col-8">{{clientData.client_name?clientData.client_name:''}}</div>
              <div class="col-4 md:col-4"><b>Status</b></div>
              <div class="col-8 md:col-8">{{clientData.status?clientData.status:''}}</div>
              <div class="col-4 md:col-4"><b>City</b></div>
              <div class="col-8 md:col-8">{{clientData.city}}</div>
              <div class="col-4 md:col-4"><b>State</b></div>
              <div class="col-8 md:col-8">{{clientData.state}}</div>
              <div class="col-4 md:col-4"><b>Pincode</b></div>
              <div class="col-8 md:col-8">{{clientData.pincode}}</div>
              <div class="col-4 md:col-4"><b>Description</b></div>
              <div class="col-8 md:col-8" v-if="clientData.description">{{clientData.description}}</div>
              <div class="col-8 md:col-8" v-else></div>
  
          
            </div>
           
          </div>
        </div>
      </div>
      <div class="col-12 md:col-8">
        <div class="card card-w-title" style="height: 800px">
      <!-- {{clientData}} -->
            <tabs
      v-model="selectedTab"
    >
    <!-- {{tabs}} -->
      <tab
        class="tab"
        v-for="(tab, i) in tabs"
        :key="`t${i}`"
        :val="tab"
        :label="tab"
        :indicator="true"
      />
    </tabs>
      <tab-panels
      v-model="selectedTab"
      :animate="true"
    >
      <tab-panel >
        <!-- {{selected_tab}} -->
        <loading
              :active="isLoadingModel"
              :can-cancel="true"
              
              color="#274461"
              :is-full-page="fullPage"
            ></loading>
            <!-- {{groupUserList}} -->
           
        
        <ClientMapVehicle   :client_id="client_id" v-if="selectedTab=='Vehicle' && client_id"></ClientMapVehicle>
        <ClientMapDepartment   :client_id="client_id" v-if="selectedTab=='Department' && client_id"></ClientMapDepartment>
        <ClientMapRoute   :client_id="client_id" v-if="selectedTab=='Route' && client_id"></ClientMapRoute>
        <ClientMapRide   :client_id="client_id"  v-if="selectedTab=='Ride' && client_id"></ClientMapRide>
      </tab-panel>
    </tab-panels>
  
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';

  import ClientMapVehicle from '../components/menu/Client_Map_Vehicle'
  import ClientMapDepartment from '../components/menu/Client_Map_Department'
  import ClientMapRoute from '../components/menu/Client_Map_Route'
  import ClientMapRide from '../components/menu/Client_Map_Ride'
  import BreadcrumbService from "../service/BreadcrumbService";

  

  import apis from "@/apis";
  
  
  export default {
    data() {
      return {
          active:"active",
          tabs : ['Vehicle','Department','Route','Ride'],
          selectedTab: "Vehicle",
          userType:"Rider",
          clientData:[],
          client_id:'',
          //group_type:"",
          //groupUserList:[], 
          //groupVehicleList:[], 
          isLoadingModel:false,
          BreadcrumbService : null,
          breadcrumbHome: {},
          breadcrumbItems: [],
      };
    },
    async mounted(){
      
      if(localStorage.getItem('saveclientdata'))
      {
         this.clientData=await JSON.parse(localStorage.getItem('saveclientdata'));
         this.client_id= await this.clientData._id;
         
         //this.group_type= await this.clientData.group_type;
      
      }

      await localStorage.setItem('saveclientdata','')
      
      
    },
    async created(){
      this.BreadcrumbService = new BreadcrumbService();
      let Breadcrum_data = this.BreadcrumbService.clientProfile()
      this.breadcrumbHome=Breadcrum_data.home;
      this.breadcrumbItems=Breadcrum_data.items;
      
    },
    methods: {

      
    
      D_M_Y_formatDate(date_parm)
      {
        if(date_parm)
        {
          let date = new Date(date_parm);
          let dd = date.getDate();
          let mm = date.getMonth() + 1;
          let yyyy = date.getFullYear();
          let new_date = dd+"-"+mm + "-" + yyyy ;
          // alert(dd)
          if(dd.toString()!="NaN")
          {
            //this.receipt_new_date = new_date;
            return new_date;
          }
          else
          {
            return date_parm;
          }
        }
      },
      toggleMenu(event) {
        this.$refs.menu.toggle(event);
      },
      onContextRightClick(event) {
        this.$refs.contextMenu.show(event);
      },
    },
    components:{
          Tabs,
      Tab,
      TabPanels,
      TabPanel,
      ClientMapVehicle,
      ClientMapDepartment,
      ClientMapRoute,
      ClientMapRide
      
      
    }
  };
  </script>