<template>
    <div>
      <div class="" style="height: 600px; overflow: scroll">
        <!-- <loading
          :active="isLoadingModel"
          :can-cancel="true"
          color="#274461"
          :is-full-page="fullPage"
        ></loading> -->
        <div
          class="col-12 lg:col-6 xl:col-12"
          v-for="(data, key) in list"
          :key="key"
        >
          <!-- <div  > -->
          <br />
          <div
            class="card mb-0"
            style="color:white;background-color: #274461; font-size: 12px; line-height: 0.9"
          >
            <div class="grid">
              <div class="col-2 md:col-2">
                <img style="width:70px;height:70px;  border-radius: 50%;" :src="(data.route_photo?data.route_photo:'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o=')">

              </div>
              <div class="grid col-4 md:col-10">
                <div class="col-6 md:col-6">
							<span>Route Name : </span><span style="text-align:right"><b>{{data.name?data.name:''}}</b></span>
						</div>
                        <div class="col-6 md:col-6">
							<span>State : </span><span style="text-align:right"><b>{{data.state?data.state:''}}</b></span>
						</div>
                        <div class="col-6 md:col-6">
							<span>City : </span><span style="text-align:right"><b>{{data.city?data.city:''}}</b></span>
						</div>
                        <div class="col-6 md:col-6">
							<span>Pincode : </span><span style="text-align:right"><b>{{data.pincode?data.pincode:''}}</b></span>
						</div>
                        <div class="col-6 md:col-6">
							<span>Start Time : </span><span style="text-align:right"><b>{{data.start_time?data.start_time:''}}</b></span>
						</div><div class="col-6 md:col-6">
							<span>End Time : </span><span style="text-align:right"><b>{{data.end_time?data.end_time:''}}</b></span>
						</div>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new </span>
                  <span class="">since last visit</span> -->
          </div>
          <!-- </div> -->
        </div>
  
        <div class="col-12" v-if="list == ''" style="text-align: left">
          <br />
          Data Not Found
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ProductService from "../../service/ProductService";
  import { FilterMatchMode } from "primevue/api";
  import apis from "@/apis";
  export default {
    props: ["client_id"],
    data() {
      return {
        isLoading: false,
        display: false,
        dropdownValue: "",
        dropdownValues: [
          { name: "Active", code: "Active" },
          { name: "In Active", code: "In Active" },
          // {name: 'London', code: 'LDN'}
        ],
  
        isLoadingModel: false,
        loading: false,
        fullPage: true,
        data1: [],
        list: [],
        search_status: false,
        search_loading: false,
  
        productDialog: false,
        search_number: "",
        display3: false,
        driver_list1: [],
        display1: false,
        id: "",
      };
    },
    methods: {
      client_map_group_list() {
        this.isLoadingModel = true;
  
        var data = { client_id: this.client_id };
        var promise = apis.group_list(data);
        promise.then((response) => {
          this.isLoadingModel = false;
          this.list = response.data.data;
        });
      },
  
      open() {
        this.display = true;
      },
  
      open1(data) {
        this.dropdownValue = { name: data.status, code: data.status };
        this.id = data._id;
        this.display1 = true;
      },
      close() {
        this.display = false;
      },
      // add_vehicle(event,data) {
      //   var data={client_id:this.client_id,vehicle_id:data._id}
      //         this.search_loading=true;
      //   var promise = apis.mappedVehcielGroup(data);
      //   promise
      //     .then(response => {
      //       this.search_loading=false;
      // 		if(response.data.status==true)
      // 		{
      // 			alert(response.data.message);
      // 			this.display=false;
      // 			this.search_number='';
      // 			this.client_map_group_list();
      // 		}
      // 		else
      // 		{
      // 			alert(response.data.data)
  
      // 		}
      //       }
      //     )
      //   console.log(data);
      // },
      // edit_vehicle(data) {
      //   var data={id:this.id,vehicle_id:data._id,status:this.dropdownValue.name}
      //     this.search_loading=true;
      //   var promise = apis.unmappedVehcielGroup(data);
      //   promise
      //     .then(response => {
      //       this.search_loading=false;
      // 		if(response.data.status==true)
      // 		{
      // 			alert(response.data.message);
      // 			this.display1=false;
      // 			this.search_number='';
      // 			this.client_map_group_list();
      // 		}
      // 		else
      // 		{
      // 			alert(response.data.data)
  
      // 		}
      //       }
      //     )
      //   console.log(data);
      // },
  
      openNew() {
        alert("here");
        //   this.reset_details();
        //   this.product = {};
        //   this.products = [];
        //   this.submitted = false;
        this.productDialog = true;
      },
      D_M_Y_formatDate(date_parm) {
        if (date_parm) {
          let date = new Date(date_parm);
          let dd = date.getDate();
          let mm = date.getMonth() + 1;
          let yyyy = date.getFullYear();
          let new_date = dd + "-" + mm + "-" + yyyy;
          // alert(dd)
          if (dd.toString() != "NaN") {
            //this.receipt_new_date = new_date;
            return new_date;
          } else {
            return date_parm;
          }
        }
      },
    },
    mounted() {
      this.client_map_group_list();
      // alert('here');
    },
  };
  </script>
  